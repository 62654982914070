<template>
  <ModelCreater :title="title" :loading="isLoading" :errors="errors" :canCreate="canCreate" @create="create">
    <CreateOrganizationStyled>
      <InputField :labelWidth="6" class="name">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.name.$model" placeholder="Name" />
        </template>
        <template v-slot:label>
          <span>Name</span>
        </template>
        <template v-slot:errors v-if="$v.name.$dirty">
          <div class="error" v-if="!$v.name.required">Field is required</div>
          <div class="error" v-if="!$v.name.minLength">Field must have at least {{ $v.name.$params.minLength.min }} letters.</div>
        </template>
      </InputField>
      <InputField :labelWidth="6" class="alias">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.alias.$model" placeholder="Alias" />
        </template>
        <template v-slot:label>
          <span>Alias</span>
        </template>
        <template v-slot:errors v-if="$v.alias.$dirty">
          <div class="error" v-if="!$v.alias.required">Field is required</div>
          <div class="error" v-if="!$v.alias.minLength">Field must have at least {{ $v.alias.$params.minLength.min }} letters.</div>
        </template>
      </InputField>
    </CreateOrganizationStyled>
  </ModelCreater>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ModelCreater from '@/components/misc/ModelCreater'
import { InputField } from '@common/components'
import get from 'lodash/get'

import { required, minLength } from 'vuelidate/lib/validators'

import CREATE_ORGANIZATION_MUTATION from '#/graphql/organizations/createOrganization.gql'

const CreateOrganizationStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
  align-items: center;
`

export default {
  components: {
    CreateOrganizationStyled,
    ModelCreater,
    InputField,
    // Multiselect,
  },
  data() {
    return {
      name: '',
      alias: '',
      errors: [],
      isLoading: false,
      isCreating: false,
    }
  },
  computed: {
    title() {
      return `Create Organization`
    },
    canCreate() {
      return !this.$v.$invalid && !this.isCreating
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(5),
    },
    alias: {
      required,
      minLength: minLength(3),
    },
  },
  methods: {
    async create() {
      this.isCreating = true
      try {
        const res = await this.$apollo.mutate({
          mutation: CREATE_ORGANIZATION_MUTATION,
          variables: {
            data: {
              name: this.name,
              alias: this.alias,
            },
          },
        })
        const id = get(res, 'data.createOrganization.id')
        if (id) {
          this.$emit('reload')
          this.$router.push({
            name: 'organization',
            params: {
              id,
            },
          })
        }
      } catch (err) {
        this.errors.push(err)
      }
      this.isCreating = false
    },
  },
}
</script>
